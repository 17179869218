import { getAlertFrequencyById } from '~/constants';
import { useCookies } from 'react-cookie';
import { isDefined } from '~/utils';
const cookieKeys = {
    USER_LOGIN_KEY: 'MKTOPT_User_Login',
    USER_SAVE_SEARCH_ALERT_KEY: 'MKTOPT_Save_Search_Alert',
};
const useGA = () => {
    const [cookies, setCookie] = useCookies([cookieKeys.USER_LOGIN_KEY]);
    const pushToDataLayer = (event, data) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(Object.assign({ event }, data));
    };
    const searchAlertPush = (data) => {
        var _a, _b, _c;
        pushToDataLayer(cookieKeys.USER_SAVE_SEARCH_ALERT_KEY, {
            searchAlertIndustry: (_a = data.query.industry) === null || _a === void 0 ? void 0 : _a.name,
            searchAlertFrequency: (_b = getAlertFrequencyById(data.alertFrequency)) === null || _b === void 0 ? void 0 : _b.alertFrequencyName,
            searchAlertSearchTerm: data.query.term,
            searchAlertCountry: (_c = data.query.country) === null || _c === void 0 ? void 0 : _c.name,
        });
    };
    const checkCookiesAndPushUserLogin = () => {
        if (!isDefined(cookies[cookieKeys.USER_LOGIN_KEY])) {
            // Session-only cookie
            setCookie(cookieKeys.USER_LOGIN_KEY, 'true', {
                path: '/',
                expires: undefined,
                maxAge: undefined,
            });
            pushToDataLayer(cookieKeys.USER_LOGIN_KEY, {
                landingPage: location.href,
            });
        }
    };
    const userLoginPush = () => {
        // If the document is still loading, then fire the event after the document is loaded
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', checkCookiesAndPushUserLogin);
        }
        else {
            checkCookiesAndPushUserLogin();
        }
    };
    return {
        searchAlertPush,
        userLoginPush,
    };
};
export default useGA;
